import "./SocialLinks.scss"
import { motion } from "framer-motion";
import resume from "../../assets/Resume - Rishi.pdf";
import { useTheme } from '../../Styles/ThemeContext';
//image imports

// import linkedinNegative from '../../assets/icons/dark/LinkedIN.png';
import linkedinColor from '../../assets/icons/light/LinkedIN.png';
// import githubNegative from '../../assets/icons/dark/Github.png';
import githubColor from '../../assets/icons/light/Github.png';
// import resumeNegative from '../../assets/icons/dark/cv.png';
import resumeColor from '../../assets/icons/light/cv.png';
// import mailNegative from '../../assets/icons/dark/mail_dark.png';
import mailColor from '../../assets/icons/light/mail_light.png';


function SocialLinks() {
    const { isDarkMode } = useTheme();

    return (
        <div className="parent-container">
            <div className="d-flex">
                <div className="links-container d-flex">
                    <motion.div
                        className="links icon-hover-effect"
                        whileHover={{ scale: 1.2 }}
                        whileTap={{ scale: 0.9 }}>
                        <a
                            href="https://www.linkedin.com/in/rishiprabhat/"
                            target="_blank"
                            rel="noreferrer"
                            className="icon-link"
                        >
                            <img className='project-link-icon' src={isDarkMode ? linkedinColor : linkedinColor} alt="link" />
                        </a>
                    </motion.div>
                    <motion.div
                        className="links links-padding icon-hover-effect"
                        whileHover={{ scale: 1.2 }}
                        whileTap={{ scale: 0.9 }}>
                        <a
                            href="https://github.com/srpn97"
                            target="_blank"
                            rel="noreferrer"
                            className="icon-link"
                        >
                            <img className='project-link-icon' src={isDarkMode ? githubColor : githubColor} alt="link" />
                        </a>
                    </motion.div>
                    <motion.div
                        className="links links-padding icon-hover-effect"
                        whileHover={{ scale: 1.2 }}
                        whileTap={{ scale: 0.9 }}>
                        <a
                            href="mailto:hello@rishis.info"
                            target="_blank"
                            rel="noreferrer"
                            className="icon-link"
                        >
                            <img className='project-link-icon' src={isDarkMode ? mailColor : mailColor} alt="link" />
                        </a>
                    </motion.div>
                    <motion.div
                        className="links links-padding icon-hover-effect"
                        whileHover={{ scale: 1.2 }}
                        whileTap={{ scale: 0.9 }}>
                        <a href={resume} target="_blank" rel="noreferrer" className="icon-link">
                            <img className='project-link-icon' src={isDarkMode ? resumeColor : resumeColor} alt="link" />
                        </a>
                    </motion.div>
                </div>
            </div>
        </div>
    )
}

export default SocialLinks;