import "./MarqueeText.scss"
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

const MarqueeText = ({ text, direction }) => {
    const [marqueeElements, setMarqueeElements] = useState([]);

    // Prepare the marquee elements to fill at least twice the screen width
    useEffect(() => {
        const width = window.innerWidth;
        let elements = '';
        while (elements.length < width * 2) {
            elements += text + ' // '; // ' // ' can be replaced with any separator or just a space
        }
        setMarqueeElements(elements.trim().split(' // '));
    }, [text]);


    // Animation control for the marquee effect
    const marqueeAnimationRight = {
        animate: {
            x: [-window.innerWidth, 0], // The key is to translate to the negative of current screen width
            transition: {
                x: {
                    repeat: Infinity,
                    repeatType: "loop",
                    duration: 60, // Control the duration to loop the text from start to finish
                    ease: "linear"
                }
            }
        }
    };

    const marqueeAnimationLeft = {
        animate: {
            x: [0, -window.innerWidth], // The key is to translate to the negative of current screen width
            transition: {
                x: {
                    repeat: Infinity,
                    repeatType: "loop",
                    duration: 60, // Control the duration to loop the text from start to finish
                    ease: "linear"
                }
            }
        }
    };

    let marqueeAnimation = {}

    if (direction === 'left') {
        marqueeAnimation = marqueeAnimationLeft;
    } else {
        marqueeAnimation = marqueeAnimationRight;
    }

    return (
        <div style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
            <motion.div
                style={{ display: 'inline-block', textTransform: 'uppercase' }}
                variants={marqueeAnimation}
                animate="animate"
                className='description line-h text'
            >
                {marqueeElements.map((el, i) => (
                    // eslint-disable-next-line
                    <span className="text" key={i}>{el}</span>
                ))}
            </motion.div>
        </div>
    );
};

export default MarqueeText