// import React, { useState, useEffect } from 'react';
import "./HomePage.scss";
import MarqueeText from "../../Components/MarqueeText/MarqueeText.js";
// import ProjectContainer from "../../Components/ProjectContainer/ProjectContainer.js";
import Header from "../../Components/Header/Header.js";
import Landing from "../../Components/Landing/Landing.js";
import { motion, useIsPresent } from "framer-motion";
// import ProjectContent from "../../Content/content.json"
import challengeIcon from '../../assets/icons/challenge.png'
import Separator from "../../Components/Separator/Separator.js";
import Bhhs from "../../Components/Bhhs/Bhhs.js";
import Capella from "../../Components/Capella/Capella.js";
import OtherProjects from "../../Components/OtherProjects/OtherProjects.js";
import AboutMe from "../../Components/AboutMe/AboutMe.js";
import Footer from "../../Components/Footer/Footer.js";
import React, { useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function HomePage() {

    const isPresent = useIsPresent();
    const projectsRef = useRef(null);
    const aboutRef = useRef(null);

    const cardVariants = {
        offscreen: {
            opacity: 0,
        },
        onscreen: {
            opacity: 1,
            transition: {
                type: "fade",
                bounce: 0.3,
                duration: 0.8,
            }
        }
    };

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const companyIdentifier = searchParams.get('company');

        if (companyIdentifier) {
            // Send an event to Google Analytics 4 with the custom dimension
            window.gtag('event', 'company_visit', {
                'companyName': companyIdentifier,
            });

            // Remove the company parameter from the URL
            searchParams.delete('company');
            const newUrl = `${location.pathname}?${searchParams.toString()}`;
            navigate(newUrl, { replace: true });
        }
    }, [location, navigate]);

    return (
        <div className='parent-container'>
            <Header projectsRef={projectsRef} aboutRef={aboutRef}></Header>

            <motion.div
                className="section-container"
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true }}
                variants={cardVariants}
            >
                <div className="section new-challenges">
                    <div>
                        <img className="challenge-icon" src={challengeIcon} alt="icon" />
                    </div>
                    <div className="challenge-text text">
                        Looking for new challenges
                    </div>
                </div>
            </motion.div>

            <motion.div
                className="section-container"
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true }}
                variants={cardVariants}
            >
                <div style={{ width: '100%', overflow: 'hidden' }}>
                    <MarqueeText direction="left" text=" • WEB DEVELOPER • FRONTEND DEVELOPER • UI DEVELOPER" />
                </div>
            </motion.div>

            <motion.div
                className="section-container"
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true }}
                variants={cardVariants}
            >
                <Landing></Landing>
            </motion.div>

            <motion.div
                className="section-container"
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true }}
                variants={cardVariants}
            >
                <div ref={projectsRef}>
                    <Separator text={'Projects'}></Separator>
                </div>
            </motion.div>

            <motion.div
                className="section-container"
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true }}
                variants={cardVariants}
            >
                <Bhhs></Bhhs>
            </motion.div>


            <motion.div
                className="section-container"
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true }}
                variants={cardVariants}
            >
                <Capella></Capella>
            </motion.div>

            <motion.div
                className="section-container"
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true }}
                variants={cardVariants}
            >
                <Separator text={'exploration'}></Separator>
            </motion.div>

            <motion.div
                className="section-container"
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true }}
                variants={cardVariants}
            >
                <OtherProjects></OtherProjects>
            </motion.div>


            <motion.div
                className="section-container"
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true }}
                variants={cardVariants}
            >
                <div ref={aboutRef} >
                    <Separator text={'about me'}></Separator>
                </div>

            </motion.div>

            <motion.div
                className="section-container"
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true }}
                variants={cardVariants}
            >
                <AboutMe></AboutMe>
            </motion.div>

            <motion.div
                className="section-container"
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true }}
                variants={cardVariants}
            >
                <Footer projectsRef={projectsRef} aboutRef={aboutRef}></Footer>
            </motion.div>


            {/* <div>
                {ProjectContent.map((project)=> {
                    return <ProjectContainer key={project.id} content={project}></ProjectContainer>
                })}
            </div> */}

            <motion.div
                initial={{ scaleX: 1 }}
                animate={{ scaleX: 0, transition: { duration: 0.5, ease: "circOut" } }}
                exit={{ scaleX: 1, transition: { duration: 0.8, ease: "circIn" } }}
                style={{ originX: isPresent ? 0 : 1 }}
                className="privacy-screen"
            />
        </div>
    );
}

export default HomePage;
