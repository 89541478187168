import React from 'react';
import './Bhhs.scss';
import image1 from '../../assets/images/bhhs/image1.png';
import image2 from '../../assets/images/bhhs/image2.png';
import image3 from '../../assets/images/bhhs/image3.png';
// import linkIcon from '../../assets/images/bhhs/link-icon.png';

const Bhhs = () => {
    return (
        <div className='section bhhs-container projects-container'>
            <div className='project-container'>
                <div className='project-title text'>
                    Berkshire Hathaway Home Services
                </div>
                <div className='project-details'>
                    <div className='project-stack'>
                        <div className='stack info-label'>
                            stack
                        </div>
                        <div className='stack-content info-value text'>
                            React, AEM, TypeScript, Java
                        </div>
                    </div>
                    <div className='project-timeline d-flex'>
                        <div className='project-year'>
                            <div className='info-label year'>
                                Year
                            </div>
                            <div className='info-value text'>
                                2022
                            </div>
                        </div>
                        <div className='project-duration'>
                            <div className='info-label duration'>
                                Duration
                            </div>
                            <div className='info-value text'>
                                8 Months
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mobile-bhhs-image-grid'>
                    <div className='imgs-container image1'>
                        <img src={image1} alt="bhhs" />
                    </div>
                    <div className='imgs-container image2'>
                        <img src={image2} alt="bhhs" />
                    </div>
                    <div className='imgs-container image3'>
                        <img src={image3} alt="bhhs" />
                    </div>
                </div>
                <div className='project-description'>
                    <p className="content-spacer content-spacer-first text">
                        {/* {content.description[0]} */}
                        The BHHS Real Estate Portal project aimed to revamp the online presence of
                        Berkshire Hathaway HomeServices, a renowned real estate brokerage firm.
                        The goal was to create a modern, user-friendly platform that catered to both
                        real estate agents and potential home buyers. Working closely with the BHHS team,
                        I contributed to every stage of the project, from wireframing and prototyping
                        to final implementation.
                    </p>
                    <p className="content-spacer text">
                        The BHHS Real Estate Portal received positive feedback from both clients and users alike,
                        significantly improving the online visibility and user engagement for Berkshire Hathaway
                        HomeServices. The project's success underscores my proficiency in front-end development
                        and my dedication to delivering high-quality, user-centric solutions.
                    </p>
                    {/* {
                    content.description.map((text, index) => {
                        if (index > 0) {
                            return <p key={index} className="content-spacer">{text}</p>
                        } else return <div key={index}></div>
                    })
                } */}
                </div>
                <div className='bhhs-project-link'>
                    <a className='project-link' href="https://www.bhhs.com/" target="_blank" rel="noopener noreferrer">view website</a>
                </div>
            </div>
            <div className='bhhs-image-grid'>
                <div className='imgs-container image1'>
                    {/* <img src={image1} alt="bhhs" /> */}
                </div>
                <div className='imgs-container image2'>
                    {/* <img src={image2} alt="bhhs" /> */}
                </div>
                <div className='imgs-container image3'>
                    {/* <img src={image3} alt="bhhs" /> */}
                </div>
            </div>
        </div>
    )
}

export default Bhhs