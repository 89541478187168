import React from 'react';
import './OtherProjects.scss';
// import { useTheme } from '../../Styles/ThemeContext';
import image1 from '../../assets/images/projects/bgry.png';
import image2 from '../../assets/images/projects/weather.png';
import image3 from '../../assets/images/projects/smarthome.png';
import image4 from '../../assets/images/projects/maps.png';
import image5 from '../../assets/images/projects/movies.png';
// import bgryLinkIcon from '../../assets/images/projects/bgryLink.png';
// import bgryLinkDarkIcon from '../../assets/images/projects/bgryLinkDark.png';
// import weatherLinkIcon from '../../assets/images/projects/weatherLink.png';
// import smartLinkIcon from '../../assets/images/projects/smartLink.png';
// import mapsLinkIcon from '../../assets/images/projects/mapsLink.png';
// import moviesLinkIcon from '../../assets/images/projects/movieLink.png';

const OtherProjects = () => {

    // const { isDarkMode } = useTheme();

    return (
        <div className='other-projects section'>
            <div className='project-card'>
                <div className='project-image-cont img-links'>
                <a href="https://www.bgry.io/" rel="noopener noreferrer" target="_blank">
                    <img className='project-image' src={image1} alt="project tile" />
                </a>
                </div>
                <div className='project-info'>
                    <div className='project-card-title text'>
                        Design Portfolio
                    </div>
                    <div className='project-card-description text'>
                        The Vue-based design portfolio, featuring custom-built components and
                        hosted on Firebase, showcases my friend's expertise in Human-Computer
                        Interaction and my proficiency in crafting tailored front-end solutions
                        that empower designers to effectively showcase their skills and impact.
                    </div>
                    <div className='project-card-link'>
                        <a className='project-link bgry-link' href="https://www.bgry.io/" rel="noopener noreferrer" target="_blank">view website</a>
                    </div>
                </div>
            </div>
            <div className='project-card'>
                <div className='project-image-cont img-links'>
                <a href="https://github.com/srpn97/simple-weather-app" rel="noopener noreferrer" target="_blank">
                    <img className='project-image' src={image2} alt="project tile" />
                </a>
                </div>
                <div className='project-info'>
                    <div className='project-card-title text'>
                        Weather App
                    </div>
                    <div className='project-card-description text'>
                        This React-based weather app, developed as a fun project to familiarize
                        myself with the latest React version, demonstrates my ability to quickly
                        adapt to new technologies and create practical, user-friendly applications
                        that showcase my front-end development skills.
                    </div>
                    <div className='project-card-link'>
                        <a className='project-link weather-link' href="https://github.com/srpn97/simple-weather-app" rel="noopener noreferrer" target="_blank">view git</a>
                    </div>
                </div>
            </div>
            <div className='project-card'>
                <div className='project-image-cont img-links'>
                <a href="https://github.com/srpn97/SmartHomeControl" rel="noopener noreferrer" target="_blank">
                    <img className='project-image' src={image3} alt="project tile" />
                </a>  
                </div>
                <div className='project-info'>
                    <div className='project-card-title text'>
                        Smart Home Solutions
                    </div>
                    <div className='project-card-description text'>
                        This smart home solution, featuring a React frontend and a Python backend
                        powered by FastAPI and Socket.IO for seamless web socket communication,
                        exemplifies my proficiency in full-stack development and my ability to
                        create innovative applications that discover and control devices within
                        a local network.
                    </div>
                    <div className='project-card-link'>
                        <a className='project-link smart-link' href="https://github.com/srpn97/SmartHomeControl" rel="noopener noreferrer" target="_blank">view git</a>
                    </div>
                </div>
            </div>
            <div className='project-card'>
                <div className='project-image-cont img-links'>
                <a href="https://github.com/srpn97/vue3-maps-integration" rel="noopener noreferrer" target="_blank">
                    <img className='project-image' src={image4} alt="project tile" />
                </a>
                </div>
                <div className='project-info'>
                    <div className='project-card-title text'>
                        Maps Integration
                    </div>
                    <div className='project-card-description text'>
                        Leveraging Vue3 and the Google Maps API, I implemented custom markers
                        and real-time traffic information in this project, while also integrating
                        live CCTV camera access for the state of California, demonstrating my
                        ability to create feature-rich, interactive maps that provide valuable
                        insights and enhance user experiences.
                    </div>
                    <div className='project-card-link'>
                        <a className='project-link maps-link' href="https://github.com/srpn97/vue3-maps-integration" rel="noopener noreferrer" target="_blank">view git</a>
                    </div>
                </div>
            </div>
            <div className='project-card'>
                <a href="https://movieticket.rishis.info/" rel="noopener noreferrer" target="_blank">
                    <img className='project-image' src={image5} alt="project tile" />
                </a>
                <div className='project-info'>
                    <div className='project-card-title text'>
                        Movie Booking
                    </div>
                    <div className='project-card-description text'>
                        This Vue3-based movie booking application, hosted on AWS and utilizing S3 for
                        updated movie listings, showcases my proficiency in serverless architecture, as
                        I implemented Lambda functions to create APIs and employed DynamoDB as the database,
                        resulting in a scalable and efficient solution for seamless movie ticket reservations.
                    </div>
                    <div className='project-card-link'>
                        <a className='project-link movies-link' href="https://movieticket.rishis.info/" rel="noopener noreferrer" target="_blank">view git</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OtherProjects