import { motion, useIsPresent } from "framer-motion";
function About() {
    const isPresent = useIsPresent();
    return (
        <div className="parent-container">
            <div className="about-page">
                <h1>
                    About
                </h1>
            </div>
            <motion.div
                initial={{ scaleX: 1 }}
                animate={{ scaleX: 0, transition: { duration: 0.5, ease: "circOut" } }}
                exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
                style={{ originX: isPresent ? 0 : 1 }}
                className="privacy-screen"
            />
        </div>
    )
}

export default About