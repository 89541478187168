import React from 'react';
import './Capella.scss';
import image1 from '../../assets/images/capella/image1.png';
import image2 from '../../assets/images/capella/image2.png';
import image3 from '../../assets/images/capella/image3.png';
// import linkIcon from '../../assets/images/capella/link-icon.png';

const Capella = () => {
    return (
        <div className='section capella-container projects-container'>
            <div className='project-container'>
                <div className='capella-title project-title text'>
                    capella university
                </div>
                <div className='project-details'>
                    <div className='project-stack'>
                        <div className='stack info-label'>
                            stack
                        </div>
                        <div className='stack-content info-value text'>
                            Vue, AEM, JavaScript, Java
                        </div>
                    </div>
                    <div className='project-timeline d-flex'>
                        <div className='project-year'>
                            <div className='info-label year'>
                                Year
                            </div>
                            <div className='info-value text'>
                                2021
                            </div>
                        </div>
                        <div className='project-duration'>
                            <div className='info-label duration'>
                                Duration
                            </div>
                            <div className='info-value text'>
                                7 Months
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mobile-capella-image-grid'>
                    <div className='imgs-container image1'>
                        <img src={image1} alt="bhhs" />
                    </div>
                    <div className='imgs-container image2'>
                        <img src={image2} alt="bhhs" />
                    </div>
                    <div className='imgs-container image3'>
                        <img src={image3} alt="bhhs" />
                    </div>
                </div>
                <div className='project-description'>
                    <p className="content-spacer content-spacer-first text">
                        {/* {content.description[0]} */}
                        The Capella University Learning Platform project aimed to enhance the digital learning
                        experience for students enrolled in Capella University's online degree programs.
                        As a senior front-end developer, I played a pivotal role in transforming the existing
                        platform into a cutting-edge educational hub that prioritized user engagement and accessibility.
                        Collaborating closely with the Capella University team, I contributed to the project's success
                        through meticulous planning, innovative design solutions, and robust implementation strategies.
                    </p>
                    <p className="content-spacer text">
                        The project's success underscores my expertise in front-end development and my commitment to 
                        delivering innovative solutions that drive positive impact and transformation in the education sector.
                    </p>
                    {/* {
                    content.description.map((text, index) => {
                        if (index > 0) {
                            return <p key={index} className="content-spacer">{text}</p>
                        } else return <div key={index}></div>
                    })
                } */}
                </div>
                <div className='capella-project-link'>
                    <a className='project-link' href="https://www.capella.edu/" rel="noopener noreferrer" target="_blank">view website</a>
                </div>
            </div>
            <div className='capella-image-grid'>
                <div className='imgs-container image1'>
                    {/* <img src={image1} alt="bhhs" /> */}
                </div>
                <div className='imgs-container image2'>
                    {/* <img src={image2} alt="bhhs" /> */}
                </div>
                <div className='imgs-container image3'>
                    {/* <img src={image3} alt="bhhs" /> */}
                </div>
            </div>
        </div>
    )
}

export default Capella