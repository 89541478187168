import "./Landing.scss";
import SocialLinks from "../../Components/SocialLinks/SocialLinks.js";
// import ProfileImage from '../../assets/images/profile.png'
// import React, { useState, useEffect } from 'react';
function Landing() {

	return (
		<div className="section landing">
			<div className="photo">
			</div>
			<div className="intro">
				<div className="content text">
					<div className="rishi">i'm rishi prabhat.</div>
					a dynamic full-stack developer passionate about crafting immersive 
					front-end experiences. I'm trying to revolutionize the digital landscape 
					through innovative coding solutions and currently seeking exciting opportunities
					to collaborate and make a meaningful impact.
				</div>
				<div className="social-links">
					<SocialLinks></SocialLinks>
				</div>
			</div>
		</div>
	)
}

export default Landing;
