import React from 'react';
import './IconSwitch.scss'; // Import the CSS file
import light from '../../assets/icons/light.svg';
import dark from '../../assets/icons/dark.svg';
const IconSwitch = ({ isOn, toggleSwitch }) => {
    // const [isOn, setIsOn] = useState(false);

    // const toggleSwitch = () => {
    //     setIsOn(!isOn);
    // };

    return (
        <div
            className={`switch ${isOn ? 'on' : 'off'}`}
            onClick={toggleSwitch}
        >
            <div
                className={`switch-circle ${isOn ? 'on' : 'off'}`}
                style={{
                    backgroundImage: `url(${isOn ? dark : light})`
                }}
            ></div>
        </div>
    );
};

export default IconSwitch;
