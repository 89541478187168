import React  from 'react';
import SocialLinks from '../SocialLinks/SocialLinks';
import Header from '../Header/Header';
import './Footer.scss'

const Footer = ({ projectsRef, aboutRef }) => {
    return (
        <>
            <div className='footer'>
                <div className='section footer-section'>
                    <div className='footer-title text'>
                        lets get in touch
                    </div>
                    <div className='footer-social-links'>
                        <SocialLinks></SocialLinks>
                    </div>
                </div>
            </div>
            <div className='section footer-header'>
                <Header projectsRef={projectsRef} aboutRef={aboutRef}></Header>
            </div>
        </>
    )
}

export default Footer