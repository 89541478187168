import React from 'react';
import './Separator.scss'

const Separator = ({text}) => {
  return (
    <div className='section separator'>
        <div className='text-m text'>{text}</div>
         <div className="line"></div>
    </div>
  )
}

export default Separator