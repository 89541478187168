import "./App.scss";
import HomePage from "./Pages/HomePage/HomePage.js";
import About from "./Pages/About/About.js";
import * as React from "react";
import { AnimatePresence } from "framer-motion";
import { useLocation, useRoutes } from "react-router-dom";
import { ThemeProvider, useTheme } from './Styles/ThemeContext.js';
import { useEffect } from 'react';

function App() {
  const element = useRoutes([
    {
      path: "/",
      element: <HomePage />
    },
    {
      path: "/about",
      element: <About />
    }
  ]);

  const location = useLocation();
  const { isDarkMode } = useTheme();

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', isDarkMode ? 'dark' : 'light');

    const isTouchDevice = () => {
      return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
    };

    if (!isTouchDevice()) {
      const cursor = document.createElement('div');
      cursor.classList.add('custom-cursor');
      document.body.appendChild(cursor);

      const moveCursor = (e) => {
        cursor.style.left = `${e.clientX}px`;
        cursor.style.top = `${e.clientY}px`;
      };

      const addTextHoverEffect = () => {
        cursor.classList.add('text-hover');
      };

      const removeTextHoverEffect = () => {
        cursor.classList.remove('text-hover');
      };

      const addLinkHoverEffect = () => {
        cursor.classList.add('link-hover');
      };

      const removeLinkHoverEffect = () => {
        cursor.classList.remove('link-hover');
      };

      const parallaxEffect = (e) => {
        const element = e.currentTarget.querySelector('.parallax-image, .parallax-nav');
        if (!element) return;
        const { offsetX, offsetY, target } = e;
        const { clientWidth, clientHeight } = target;
        const moveX = ((offsetX / clientWidth) * 2 - 1) * 15; // Adjust the intensity
        const moveY = ((offsetY / clientHeight) * 2 - 1) * 15; // Adjust the intensity
        element.style.transform = `translate(${moveX}px, ${moveY}px)`;
      };

      const resetParallaxEffect = (e) => {
        const element = e.currentTarget.querySelector('.parallax-image, .parallax-nav');
        if (element) {
          element.style.transform = `translate(0, 0)`;
        }
      };

      document.addEventListener('mousemove', moveCursor);

      // Add hover effects for text elements
      document.querySelectorAll('.text').forEach(el => {
        el.addEventListener('mouseover', addTextHoverEffect);
        el.addEventListener('mouseout', removeTextHoverEffect);
      });

      // Add hover effects for links and .nav elements
      document.querySelectorAll('a, .nav-links').forEach(el => {
        el.addEventListener('mouseover', addLinkHoverEffect);
        el.addEventListener('mouseout', removeLinkHoverEffect);
      });

      // Add parallax effect for images, nav elements, and a tags
      document.querySelectorAll('.project-image, .nav-links, a').forEach(el => {
        if (el.classList.contains('project-image')) {
          el.classList.add('parallax-image');
        } else if (el.classList.contains('nav-links') || el.tagName === 'A') {
          el.classList.add('parallax-nav');
        }
        if (el.parentElement) {
          el.parentElement.classList.add('parallax-container');
          el.parentElement.addEventListener('mousemove', parallaxEffect);
          el.parentElement.addEventListener('mouseout', resetParallaxEffect);
        }
      });
      return () => {
        document.removeEventListener('mousemove', moveCursor);
        document.querySelectorAll('.text').forEach(el => {
          el.removeEventListener('mouseover', addTextHoverEffect);
          el.removeEventListener('mouseout', removeTextHoverEffect);
        });
        document.querySelectorAll('a, .nav-links').forEach(el => {
          el.removeEventListener('mouseover', addLinkHoverEffect);
          el.removeEventListener('mouseout', removeLinkHoverEffect);
        });
        document.querySelectorAll('.project-image, .nav-links', 'a').forEach(el => {
          if (el.parentElement) {
            el.parentElement.removeEventListener('mousemove', parallaxEffect);
            el.parentElement.removeEventListener('mouseout', resetParallaxEffect);
            el.classList.remove('parallax-image');
            el.classList.remove('parallax-nav');
            el.parentElement.classList.remove('parallax-container');
          }
        });
        cursor.remove();
    }
    };
  }, [isDarkMode]);

  if (!element) return null;

  return (
    <div>
      <AnimatePresence mode="wait" initial={false}>
        {React.cloneElement(element, { key: location.pathname })}
      </AnimatePresence>
    </div>
  );
}

const RootApp = () => (
  <ThemeProvider>
    <App />
  </ThemeProvider>
);

export default RootApp;
