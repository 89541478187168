import React from 'react';
import './AboutMe.scss';
import group from '../../assets/images/about/group.jpg';
import event from '../../assets/images/about/event.png';
import map from '../../assets/images/about/map.png';
import racing from '../../assets/images/about/racing.png';
import watch from '../../assets/images/about/watch.png';




const AboutMe = () => {
    return (
        <div className='section capella-container projects-container'>
            <div className='project-container'>
                <div className='about-title project-title text'>
                    Beyond the Code: A Multifaceted Journey
                </div>
                <div className='mobile-about-image-grid'>
                    <div className='imgs-container image1'>
                        <img src={map} alt="about me" />
                    </div>
                    <div className='imgs-container image2'>
                        <img src={event} alt="about me" />
                    </div>
                    <div className='imgs-container image3'>
                        <img src={group} alt="about me" />
                    </div>
                    <div className='imgs-container image4'>
                        <img src={watch} alt="about me" />
                    </div>
                    <div className='imgs-container image5'>
                        <img src={racing} alt="about me" />
                    </div>
                </div>
                <div className='about-description'>
                    <p className="content-spacer content-spacer-first text">
                        {/* {content.description[0]} */}
                        Beyond the lines of code, I embrace the richness of life's experiences. 
                        Whether indulging in culinary adventures at new restaurants, diving into 
                        epic gaming quests, or simply relishing moments of camaraderie with cherished 
                        friends, I find joy in every interaction and discovery.
                    </p>
                    <p className="content-spacer text">
                        Currently, my attention is captivated by the Fallout TV series, where each 
                        episode unveils a compelling narrative set in a dystopian world. It's a 
                        fusion of suspense, adventure, and profound themes that continually pique my interest.

                    </p>
                    <p className="content-spacer text">
                        With a fervor for development, an admiration for Lewis Hamilton's remarkable 
                        journey, a deep-seated value for genuine connections, and a fascination for 
                        immersive storytelling, I'm eager to intertwine my passions and expertise in 
                        pursuit of impactful contributions within the tech sphere. Let's create 
                        something extraordinary together.

                    </p>
                    {/* {
                    content.description.map((text, index) => {
                        if (index > 0) {
                            return <p key={index} className="content-spacer">{text}</p>
                        } else return <div key={index}></div>
                    })
                } */}
                </div>
            </div>
            <div className='about-image-grid'>
                <div className='imgs-container image1'>
                    {/* <img src={image1} alt="bhhs" /> */}
                </div>
                <div className='imgs-container image2'>
                    {/* <img src={image2} alt="bhhs" /> */}
                </div>
                <div className='imgs-container gallery'>
                    <img className='gallery-img' src={group} alt="" />
                </div>
                <div className='imgs-container image3'>
                    {/* <img src={image3} alt="bhhs" /> */}
                </div>
                <div className='imgs-container image4'>
                    {/* <img src={image3} alt="bhhs" /> */}
                </div>
            </div>
        </div>
    )
}

export default AboutMe