import React from 'react';
import "./Header.scss";
import resume from "../../assets/Resume - Rishi.pdf";
import IconSwitch from '../IconSwitch/IconSwitch.js';
import { useTheme } from '../../Styles/ThemeContext';
const Header = ({ projectsRef, aboutRef }) => {

    const { isDarkMode, toggleTheme } = useTheme();

    const scrollToRef = (ref) => {
        if (ref !== '') {
            window.scrollTo({
                top: ref.current.offsetTop,
                behavior: 'smooth'
            });
        }
    };

    const handleResumeDownload = () => {
        const link = document.createElement('a');
        link.href = resume;
        link.download = 'Resume - Rishi.pdf';
        link.click();
    };
    return (
        <div className='section header'>
            <div className='logo text'>
                Rishi Prabhat
            </div>
            <div className='navigation'>
                <div onClick={() => scrollToRef(projectsRef)}>
                    <div className='nav-links'>Projects</div>
                </div>
                <div onClick={() => scrollToRef(aboutRef)}>
                    <div className='nav-links'>About</div>
                </div>
                <div onClick={handleResumeDownload} className='nav-resume'>
                    <div className='nav-links'>Resume</div>
                </div>
                <div>
                    <IconSwitch isOn={isDarkMode} toggleSwitch={toggleTheme}></IconSwitch>
                </div>
            </div>
        </div>
    )
}

export default Header